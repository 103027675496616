@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  margin: 0;
  padding-top: 40px;
}
